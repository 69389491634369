<template>
	<main>
		<HeaderTab :title="$t('global.cards_dashboard')"  :return_action="'navigationBack'"/>
		<div id="content">
			<shutter-panel ref="shutterPanel" />
		</div>
	</main>
</template>

<script type="text/javascript">
import Shutter from "@/mixins/Shutter.js"
import ShutterCards from "@/mixins/shutters-manager/Cards.js"

export default {
	name: 'Cards',
	mixins: [Shutter, ShutterCards],
	data () {
		return {}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		init_component() {
			this.setupCardsDashboard()
		}
	},

	components: {
		HeaderTab: () => import('@/components/HeaderTab')
	}
};

</script>