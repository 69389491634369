export default {
    methods: {
        getCardsShutter(props) {
            return {
                'cards-dashboard': {
                    name: 'search-sire',
                    title: this.$t('global.cards_dashboard'),
                    cmpPath: 'views/CardsDashboard'
                }
            }
        },

        setupCardsDashboard() {
            const shutters = this.getCardsShutter({})
            this.shutterPanel().open(shutters['cards-dashboard'])
        },

        async setupTestShutter(code, callback = () => false) {
            const cmp = {
				registre_elevage: 'components/AjoutLieu',
				bien_etre_animal: 'components/AjoutContact',
                registre_transport: 'components/Mouvements/AddVehicule',
				// horse_create_one: 'components/Monte/HorseCreationMonte',
                // horse_create_lot: 'components/Horse/Ajout/LotSire',
                // act_create_select: 'components/Horse/HorseSelectionV2',
                // mvt_create_select: 'components/Horse/HorseSelectionV2',
                // tiers_create: 'views/TiersAjout',
                // tiers_manage_select: 'components/Horse/HorseSelectionV2',
                pension_create_select: 'components/Accounting/AccountingPlanSelection',
                accountingplan_create: 'components/Accounting/Accounting',
                accountingaccount_create_select: 'components/Accounting/AccountingPlanSelection',
                article_create_select: 'components/Accounting/AccountingPlanSelection',
                bank_create_select: 'components/Accounting/AccountingPlanSelection',
                link_acts_articles_select: 'components/Accounting/AccountingPlanSelection',
                accountingaccount_pappers: 'components/Accounting/AccountingPappers',
                tiers_accountant_create: 'views/TiersAccountantAjout',
            }

			const name = code
            const props = {
                shutter_name: name,
                code_onboarding: code,
                unique: true,
                type_code: code == 'registre_elevage' ? 'RESI' : null
            }

            this.shutterPanel().keepFirst()
            const codes = ['act_create', 'mvt_create', 'tiers_manage', 'pension_create', 'accountingaccount_create', 'article_create', 'bank_create', 'link_acts_articles']

            if(codes.includes(code)) {
                this.shutterPanel().open({
                    name: name + '_select',
                    title: this.$t('cards.shutter.select_accountingplan'),
                    cmpPath: cmp[code + '_select'],
                    props,
                    onOk: callback
                })
            }
            else {
                this.shutterPanel().open({
                    name: name,
                    title: this.$t('cards.shutter.' + code),
                    cmpPath: cmp[code],
                    props,
                    onOk: callback
                })
            }
        }
    }
}